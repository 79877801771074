import axios from 'axios';

const api = axios.create({
    baseURL: import.meta.env.VITE_GATEWAY_URL + "/api/landing-page-ms",
    headers: {
        'API-Key': import.meta.env.VITE_API_KEY,
        'Content-Type': 'application/json',
    }
});

export default api;